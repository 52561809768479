import React from "react";
import styled from "styled-components";
import starling from "../images/star.png";
import enterprise from "../images/enterprise.png";

import ramen from "../images/ramen.jpg";
import soba from "../images/soba.jpg";
import matsuri from "../images/matsuri.jpg";
import badminton from "../images/badminton.jpeg";
import football from "../images/football.jpg";
import basketball from "../images/basketball.jpeg";
import ps4 from "../images/ps4.jpg";
import nintendo from "../images/nintendo.jpeg";
import smash from "../images/smash.jpeg";
import katsudon from "../images/katsudon.jpeg";
import monopoly from "../images/monopoly.jpg";
import tennis from "../images/table-tennis.jpeg";
import allColors from "./allColors";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

const hobbiesPictures = [
  ramen,
  badminton,
  ps4,
  soba,
  football,
  nintendo,
  matsuri,
  basketball,
  smash,
  katsudon,
  monopoly,
  tennis,
];

const Heading = styled.h2`
  color: ${allColors.darkBlue};
  margin: 0;
  font-size: 2rem;
`;

const ColorTab = styled.div`
  height: auto;
  width: 0.3rem;
  background-color: ${allColors.orange};
  margin-right: 1rem;
`;

const HeadingContainer = styled.div`
  height: auto;
  display: flex;
  margin-bottom: 3rem;
`;

const AboutMeMobileContainer = styled.div`
  padding: 2rem;
  color: ${allColors.text};
`;

const workIcon = [
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="building"
    class="svg-inline--fa fa-building fa-w-14"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12 12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z"
    ></path>
  </svg>,
];

const interestsIcon = [
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="stars"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    class="svg-inline--fa fa-stars fa-w-16"
  >
    <path
      fill="currentColor"
      d="M259.68734,85.96808l49.6461,20.70054,20.70378,49.645a6.65746,6.65746,0,0,0,11.92616,0l20.70182-49.645,49.6461-20.70054a6.6661,6.6661,0,0,0,0-11.92542L362.6652,53.34017l-20.70182-49.643a6.65746,6.65746,0,0,0-11.92616,0l-20.70378,49.643-49.6461,20.70249a6.6661,6.6661,0,0,0,0,11.92542Zm249.3653,133.26937L469.3385,202.67936l-16.55912-39.71168a5.32691,5.32691,0,0,0-9.54132,0L426.677,202.67936,386.9648,219.23745a5.33414,5.33414,0,0,0,0,9.54072L426.677,245.33822l16.56107,39.70972a5.32534,5.32534,0,0,0,9.54132,0l16.55912-39.70972,39.71414-16.56005a5.33414,5.33414,0,0,0,0-9.54072ZM364.30783,267.30434,259.492,252.00793l-46.90773-95.20022a22.76642,22.76642,0,0,0-20.68816-12.79648,22.40194,22.40194,0,0,0-20.50065,12.79648l-46.90773,95.20022L19.67194,267.30434C.89009,270.00739-6.7039,293.2098,6.984,306.50627L82.89269,380.504,64.89212,485.09457C62.29829,500.00037,74.17366,512,87.48658,512a22.24712,22.24712,0,0,0,10.68784-2.703l93.81546-49.38912L285.80535,509.297a22.859,22.859,0,0,0,33.2823-24.20239L301.18084,380.504l75.90865-73.99774C390.68367,293.2098,383.08968,270.00739,364.30783,267.30434ZM249.4917,363.8014l13.68794,79.40382-71.18976-37.49885-71.31475,37.49885,13.71918-79.40382L76.58,307.50624l79.815-11.5934,35.59487-72.20092,35.59488,72.20092,79.815,11.5934Z"
      class=""
    ></path>
  </svg>,
];

const Subheading = styled.div`
  display: flex;
  color: ${allColors.lightBlue};
  font-size: 1.3rem;
  svg {
    width: 1.3rem;
    height: auto;
    margin-right: 0.5rem;
  }
`;

const EmplomentSection = styled.div`
  display: flex;
  border: solid 1px gray;
  margin-bottom: ${(props) => props.bottom};
  height: auto;
  img {
    width: 3rem;
  }
`;

const EmploymentTitle = styled.p`
  font-weight: bold;
  color: ${allColors.orange};
`;

const EmploymentJobTitle = styled.div`
  color: ${allColors.pink};
`;

const EmploymentLogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px gray;
  width: 10rem;
  background-color: #ffffe6;
`;

const EmploymentDescription = styled.div`
  margin: 0 1rem;
  width: 150%;
`;

const PicturesCollection = styled.div`
  width: 100vw;

  margin: 0 0 3rem 0;
  // display: flex;

  img {
    width: 5rem;
    height: 8rem;
    border: solid 1px white;
  }
`;

const AboutMeMobile = () => {
  return (
    <div>
      <AboutMeMobileContainer>
        <HeadingContainer>
          <ColorTab />
          <Heading>ABOUT ME</Heading>
        </HeadingContainer>
        <p>
          I developed an interest in coding back in 2018 and leisurely started
          to learn about it in my spare time.
        </p>
        <p>
          I decided that this was definitely something I wanted to focus more on
          and began to take it seriously.
        </p>
        <Subheading>
          {workIcon}
          <p>Employment</p>
        </Subheading>

        <EmplomentSection bottom="1rem">
          <EmploymentLogoDiv>
            <a href="https://www.starlingbank.com/" target="_blank">
              <img src={starling} />
            </a>
          </EmploymentLogoDiv>

          <EmploymentDescription>
            <EmploymentTitle>Starling Bank</EmploymentTitle>
            <EmploymentJobTitle>
              <p>CSTM</p>
              <p>(01/2019 - present)</p>
            </EmploymentJobTitle>
            <p>I currently work as a member of the Operations team.</p>
          </EmploymentDescription>
        </EmplomentSection>

        <EmplomentSection>
          <EmploymentLogoDiv>
            <a href="https://www.enterprise.co.uk/en/home.html" target="_blank">
              <img src={enterprise} />
            </a>
          </EmploymentLogoDiv>

          <EmploymentDescription>
            <EmploymentTitle>Enterprise Rent-A-Car</EmploymentTitle>
            <EmploymentJobTitle>
              <p>Management Assistant</p>
              <p>(02/2017 - 12/2018)</p>
            </EmploymentJobTitle>
            <p>Before this I worked at Enterprise as a Management Assistant.</p>
          </EmploymentDescription>
        </EmplomentSection>

        <Subheading>
          {interestsIcon}
          <p>Interests</p>
        </Subheading>
        <div>
          <p>
            I really enjoy playing sports, gaming (both console and board-games)
            and all things Japan.
          </p>
          <p>
            I've been playing games with friends and family from as early as I
            can remember.
          </p>
          <p>
            In 2012 I lived in a Japanese city called Kagoshima. While there, I
            began playing table tennis and still play whenever I get the chance.
          </p>
        </div>
      </AboutMeMobileContainer>
      <PicturesCollection>
        <Carousel
          slidesPerPage={4}
          infinite
          centered
          animationSpeed={1000}
          stopAutoPlayOnHover
          draggable="true"
        >
          {hobbiesPictures.map((elem) => {
            return <img src={elem} />;
          })}
        </Carousel>
      </PicturesCollection>
    </div>
  );
};

export default AboutMeMobile;
