import React from "react";
import styled from "styled-components";

import yama from "../images/yama.jpg";
import allColors from "./allColors";

const HeroPageMobileContainer = styled.div`
  background-image: url(${yama});
  background-attachment: 100% 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${allColors.white};
  background-color: #034855;

  h1 {
    animation-name: nameAnimation;
    animation-duration: 2s;
    animation-timing-function: ease;
    color: ${allColors.orange};
    margin: 10rem 0 0 2rem;
    font-size: 3rem;
  }

  @keyframes nameAnimation {
    0% {
      color: transparent;
    }

    50% {
      color: transparent;
    }

    100% {
      color: ${allColors.orange};
    }
  }

  p {
    margin-left: 2rem;
    font-size: 2rem;
    animation-name: taglineAnimation;
    animation-duration: 4s;
    animation-timing-function: ease;

    @keyframes taglineAnimation {
      0% {
        color: transparent;
      }

      50% {
        color: transparent;
      }

      100% {
        color: ${allColors.white};
      }
    }
  }
`;

const Tint = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${allColors.darkBlue};
  opacity: 0.7;
`;

const HeroPageMobile = () => {
  return (
    <HeroPageMobileContainer>
      <Tint>
        <h1>Olawale Koiki</h1>
        <p>Front-End Web Developer</p>
      </Tint>
    </HeroPageMobileContainer>
  );
};

export default HeroPageMobile;
