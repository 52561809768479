import React from "react";
import styled from "styled-components";
import allColors from "./allColors";

const icons = [
  {
    icon: (
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fab"
        data-icon="github-square"
        className="svg-inline--fa fa-github-square fa-w-14"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM277.3 415.7c-8.4 1.5-11.5-3.7-11.5-8 0-5.4.2-33 .2-55.3 0-15.6-5.2-25.5-11.3-30.7 37-4.1 76-9.2 76-73.1 0-18.2-6.5-27.3-17.1-39 1.7-4.3 7.4-22-1.7-45-13.9-4.3-45.7 17.9-45.7 17.9-13.2-3.7-27.5-5.6-41.6-5.6-14.1 0-28.4 1.9-41.6 5.6 0 0-31.8-22.2-45.7-17.9-9.1 22.9-3.5 40.6-1.7 45-10.6 11.7-15.6 20.8-15.6 39 0 63.6 37.3 69 74.3 73.1-4.8 4.3-9.1 11.7-10.6 22.3-9.5 4.3-33.8 11.7-48.3-13.9-9.1-15.8-25.5-17.1-25.5-17.1-16.2-.2-1.1 10.2-1.1 10.2 10.8 5 18.4 24.2 18.4 24.2 9.7 29.7 56.1 19.7 56.1 19.7 0 13.9.2 36.5.2 40.6 0 4.3-3 9.5-11.5 8-66-22.1-112.2-84.9-112.2-158.3 0-91.8 70.2-161.5 162-161.5S388 165.6 388 257.4c.1 73.4-44.7 136.3-110.7 158.3zm-98.1-61.1c-1.9.4-3.7-.4-3.9-1.7-.2-1.5 1.1-2.8 3-3.2 1.9-.2 3.7.6 3.9 1.9.3 1.3-1 2.6-3 3zm-9.5-.9c0 1.3-1.5 2.4-3.5 2.4-2.2.2-3.7-.9-3.7-2.4 0-1.3 1.5-2.4 3.5-2.4 1.9-.2 3.7.9 3.7 2.4zm-13.7-1.1c-.4 1.3-2.4 1.9-4.1 1.3-1.9-.4-3.2-1.9-2.8-3.2.4-1.3 2.4-1.9 4.1-1.5 2 .6 3.3 2.1 2.8 3.4zm-12.3-5.4c-.9 1.1-2.8.9-4.3-.6-1.5-1.3-1.9-3.2-.9-4.1.9-1.1 2.8-.9 4.3.6 1.3 1.3 1.8 3.3.9 4.1zm-9.1-9.1c-.9.6-2.6 0-3.7-1.5s-1.1-3.2 0-3.9c1.1-.9 2.8-.2 3.7 1.3 1.1 1.5 1.1 3.3 0 4.1zm-6.5-9.7c-.9.9-2.4.4-3.5-.6-1.1-1.3-1.3-2.8-.4-3.5.9-.9 2.4-.4 3.5.6 1.1 1.3 1.3 2.8.4 3.5zm-6.7-7.4c-.4.9-1.7 1.1-2.8.4-1.3-.6-1.9-1.7-1.5-2.6.4-.6 1.5-.9 2.8-.4 1.3.7 1.9 1.8 1.5 2.6z"
        ></path>
      </svg>
    ),
    url: "https://github.com/Wale-K",
  },
  {
    icon: (
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fab"
        data-icon="linkedin-in"
        class="svg-inline--fa fa-linkedin-in fa-w-14"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
        ></path>
      </svg>
    ),
    url: "https://www.linkedin.com/in/olawale-koiki-51830185/",
  },
];

const wave = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="hand-sparkles"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    class="svg-inline--fa fa-hand-sparkles fa-w-20"
  >
    <path
      fill="currentColor"
      d="M471.38,467.5l-1-.43-1-.49a37.63,37.63,0,0,1-6.06-4.23,31.74,31.74,0,0,1-9.47,1.65H273.14a32.29,32.29,0,0,1-26.69-14.3L132.34,277.8c-18-25.71,20.7-54.1,39.3-27.5l37.81,54.4c4.5,6.5,14.59,3.21,14.59-4.61V104c0-31.8,48-31.7,48,0V248a8,8,0,0,0,8,8h16a8,8,0,0,0,8-8V72c0-31.8,48-31.7,48,0V248a8,8,0,0,0,8,8h16a8,8,0,0,0,8-8V104c0-31.8,48-31.7,48,0V248a8,8,0,0,0,8,8h16a8,8,0,0,0,8-8V184c0-31.8,48-31.7,48,0v88.3a321.9,321.9,0,0,1-6.2,62.79L495.6,386.4l13.27-5.53,15.63-37.49.41-1,.47-.95c5.83-11.74,18-19.6,31-20.92A371.21,371.21,0,0,0,560,272.3V184A71.94,71.94,0,0,0,488,112c-2.7,0-5.4.2-8,.41V104a72.08,72.08,0,0,0-72-72,70.77,70.77,0,0,0-18.59,2.41,72,72,0,0,0-122.81,0A70.79,70.79,0,0,0,248,32a72.08,72.08,0,0,0-72,72v92.09c-10.5-3.68-38.09-10.18-65.29,8.91A72.13,72.13,0,0,0,93,305.3l113.5,171a79.81,79.81,0,0,0,66.6,35.7H453.75a79.85,79.85,0,0,0,57-24.3l-1.9-4.57ZM86,156.3l20.69-49.63h0l.09,0L156.3,86A7.29,7.29,0,0,0,160,80h0A7.28,7.28,0,0,0,156.3,74L106.73,53.37l-.07,0L86,3.7A6.65,6.65,0,0,0,74,3.7L53.34,53.33l-.05,0L3.7,74A7.28,7.28,0,0,0,0,80H0A7.29,7.29,0,0,0,3.7,86l49.57,20.67.07,0L74,156.3a6.65,6.65,0,0,0,11.92,0ZM307.58,288a4,4,0,0,0-7.15,0L288,317.79l-29.79,12.42a4,4,0,0,0,0,7.15L288,349.78l12.42,29.78a4,4,0,0,0,7.15,0L320,349.78l29.79-12.42a4,4,0,0,0,0-7.15L320,317.79ZM640,432A7.28,7.28,0,0,0,636.3,426l-49.57-20.67-.07,0L566,355.7a6.65,6.65,0,0,0-11.92,0l-20.7,49.63-.05,0L483.7,426A7.28,7.28,0,0,0,480,432h0A7.29,7.29,0,0,0,483.7,438l49.57,20.67.07,0L554,508.3a6.65,6.65,0,0,0,11.92,0l20.69-49.63h0l.09-.05L636.3,438A7.29,7.29,0,0,0,640,432h0Z"
      class=""
    ></path>
  </svg>
);

const BannerContainer = styled.div`
  padding: 10vh 0;
  background-color: ${allColors.white};
  color: ${allColors.pink};
  display: flex;
  flex-direction: column;

  align-items: center;

  svg:first-child {
    transform: rotate(45deg);
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0;
  }

  > svg {
    width: 5rem;
    color: ${allColors.orange};
  }
`;

const BigText = styled.p`
  background-color: ${allColors.lightBlue};
  color: ${allColors.white};
  width: auto;
  font-size: 2rem;
  padding: 5vw;

  a {
    text-decoration: none;
    color: ${allColors.white};
  }
`;

const Footer = styled.div`
  background-color: ${allColors.darkBlue};
  padding-right: 2rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${allColors.white};

  a {
    color: ${allColors.white}
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 1rem;
  }
  a:visited {
    color: ${allColors.white};
  }

  p {
    margin: 0;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${allColors.white};

    :hover {
      color: ${allColors.orange};
    }
  }
`;

const Tagline = styled.p`
  color: ${allColors.white};
  a {
    color: ${allColors.pink};
    :visited {
      color: ${allColors.white};
    }
  }
`;

const Banner = () => {
  return (
    <div>
      <BannerContainer>
        {wave}
        <BigText>
          <a href="mailto:o.koiki@protonmail.com">Don't be a stranger!</a>
        </BigText>

        <Tagline>
          <a href="mailto:o.koiki@protonmail.com">Please drop me an email.</a>
        </Tagline>
      </BannerContainer>
      <Footer>
        <p>Olawale Koiki</p>
        {icons.map((elem) => {
          return (
            <a href={elem.url} target="_blank">
              <svg>{elem.icon}</svg>
            </a>
          );
        })}
      </Footer>
    </div>
  );
};

export default Banner;
