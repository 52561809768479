import React from "react";
import styled from "styled-components";
import allColors from "./allColors";
import djtomiwa from "../images/dj-tomiwa-white.png";
// import pokeball from "../images/pokeball.png";

import { icons, projectsArray, JankenDiv } from "../utilities";

const SkillsDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;
  svg {
    width: 1rem;
    color: ${allColors.white};
    margin-right: 0.5rem;
  }
`;

const LeftSide = styled.div`
  text-align: right;
  padding-right: 3vw;
  width: 34.9%;
`;

const RightSide = styled.div`
  width: 54vw;
`;

const Heading = styled.h2`
  color: ${allColors.white};
  margin: 0;
  font-size: 3rem;
  border-right: solid 10px ${allColors.orange};
  padding-right: 2rem;
`;

const ProjectPictureDiv = styled.div`
  display: flex;
  background-color: ${allColors.lightBlue};
  width: 10rem;
  height: 10rem;
  flex-shrink: 0;

  justify-content: center;
  align-items: center;
  font-size: 3.5rem;
  font-weight: bold;
  p {
    margin: 0;
  }

  img {
    width: 9rem;
  }

  svg {
    width: 5rem;
    height: auto;
    margin: 0;
  }

  ${JankenDiv} {
    svg {
      width: 3rem;
      height: 3rem;
      margin: 0.5rem;
    }
  }
`;

const ProjectTitle = styled.p`
  font-weight: bold;

  color: ${allColors.orange};
`;

const SkillsStyle = styled.p`
  color: ${allColors.pink};
`;

const ProjectsDesktopContainer = styled.div`
  padding: 10vh 0;
  width: 100vw;
  color: ${allColors.white};
  background-color: ${allColors.darkBlue};
  display: flex;

  a {
    text-decoration: none;
  }
  a:visited {
  }
  a:hover {
    color: ${allColors.pink};
  }
`;

const SmallIcons = styled.svg`
  width: 1rem;
  height: 1rem;
  color: ${allColors.pink};
  margin-right: 0.5rem;
  :hover {
    color: ${allColors.pink};
  }
`;

const ProjectInformationDiv = styled.div`
  margin-left: 0.5rem;
`;

const ProjectsDesktop = () => {
  return (
    <ProjectsDesktopContainer>
      <LeftSide>
        <Heading>PROJECTS</Heading>
      </LeftSide>

      <RightSide>
        {projectsArray.map((elem) => {
          return (
            <SkillsDiv>
              <ProjectPictureDiv>{elem.icon}</ProjectPictureDiv>

              <ProjectInformationDiv>
                <a
                  href={elem.githubUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmallIcons>{icons.github}</SmallIcons>
                </a>
                <a
                  href={elem.browserUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmallIcons>{icons.browser}</SmallIcons>
                </a>
                <ProjectTitle>{elem.name}</ProjectTitle>
                <SkillsStyle>{elem.skills}</SkillsStyle>
                {elem.description}
              </ProjectInformationDiv>
            </SkillsDiv>
          );
        })}
      </RightSide>
    </ProjectsDesktopContainer>
  );
};

export default ProjectsDesktop;
