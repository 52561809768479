import React from "react";
import styled from "styled-components";
import allColors from "./allColors";
// import pokeball from "../images/pokeball.png";
import djtomiwa from "../images/dj-tomiwa-white.png";
import { icons, projectsArray, JankenDiv } from "../utilities";

const SkillsDiv = styled.div`
  margin-bottom: 1rem;
  svg {
    width: 1rem;
    color: ${allColors.white};
  }
`;

const Heading = styled.h2`
  color: ${allColors.white};
  margin: 0;
  font-size: 2rem;
`;

const ProjectTitle = styled.p`
  font-weight: bold;

  color: ${allColors.orange};
`;

const SkillsStyle = styled.p`
  color: ${allColors.pink};
`;

const ColorTab = styled.div`
  height: auto;
  width: 0.3rem;
  background-color: ${allColors.orange};
  margin-right: 1rem;
`;

const HeadingContainer = styled.div`
  height: auto;
  display: flex;
  margin-bottom: 3rem;
`;

const ProjectsMobileContainer = styled.div`
  padding: 2rem;
  color: ${allColors.white};
  background-color: ${allColors.darkBlue};

  a {
    text-decoration: none;
    margin-right: 0.5rem;
  }
  a:visited {
  }
  a:hover {
    color: ${allColors.pink};
  }
`;

const ProjectPictureDiv = styled.div`
  background-color: ${allColors.lightBlue};
  color: ${allColors.white};
  width: 7.5rem;
  height: 5rem;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  svg {
    width: 3rem;
    height: auto;
  }

  img {
    width: 7rem;
  }

  ${JankenDiv} {
    svg {
      width: 2rem;
      height: 2rem;
      margin: 0.2rem;
    }
  }
`;

const ProjectsMobile = () => {
  return (
    <ProjectsMobileContainer>
      <HeadingContainer>
        <ColorTab />
        <Heading>PROJECTS</Heading>
      </HeadingContainer>

      {projectsArray.map((elem) => {
        return (
          <SkillsDiv>
            <ProjectPictureDiv>{elem.icon}</ProjectPictureDiv>

            <a href={elem.githubUrl} target="_blank" rel="noopener noreferrer">
              {icons.github}
            </a>
            <a href={elem.browserUrl} target="_blank" rel="noopener noreferrer">
              {icons.browser}
            </a>
            <ProjectTitle>{elem.name}</ProjectTitle>
            <SkillsStyle>{elem.skills}</SkillsStyle>
            {elem.description}
          </SkillsDiv>
        );
      })}
    </ProjectsMobileContainer>
  );
};

export default ProjectsMobile;
