import React from "react";
import "./App.css";
import styled from "styled-components";

import HeroPageMobile from "./components/HeroPageMobile";
import HeroPageDesktop from "./components/HeroPageDesktop";
import AboutMeMobile from "./components/AboutMeMobile";
import AboutMeDesktop from "./components/AboutMeDesktop";
import ProjectsMobile from "./components/ProjectsMobile";
import ProjectsDesktop from "./components/ProjectsDesktop.js";
import Banner from "./components/Banner";

const MobileView = styled.div`
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const DesktopView = styled.div`
  display: none;
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const App = () => {
  return (
    <div>
      <MobileView>
        <HeroPageMobile />
        <AboutMeMobile />
        <ProjectsMobile />
      </MobileView>
      <DesktopView>
        <HeroPageDesktop />
        <AboutMeDesktop />
        <ProjectsDesktop />
      </DesktopView>
      <Banner />
    </div>
  );
};

export default App;
