const allColors = {
  darkBlue: "#034855",
  orange: "#FAB10C",
  lightBlue: "#2AA1B7",
  pink: "#DE607E",
  text: "#474747",
  white: "white",
};

export default allColors;
