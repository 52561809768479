import React from "react";
import styled from "styled-components";
import djtomiwa from "./images/dj-tomiwa-white.png";

export const StyledRock = styled.svg`
  transform: rotate(180deg);
`;
export const StyledPaper = styled.svg`
  transform: rotate(45deg);
`;
export const StyledScisscors = styled.svg`
  transform: rotate(45deg);
`;

export const JankenDiv = styled.div`
  div {
    display: flex;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const icons = {
  github: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="github"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496 512"
      class="svg-inline--fa fa-github fa-w-16"
    >
      <path
        fill="currentColor"
        d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
        class=""
      ></path>
    </svg>
  ),
  browser: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="browser"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="svg-inline--fa fa-browser fa-w-16"
    >
      <path
        fill="currentColor"
        d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM48 92c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V92zm416 334c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V168h416v258zm0-310c0 6.6-5.4 12-12 12H172c-6.6 0-12-5.4-12-12V92c0-6.6 5.4-12 12-12h280c6.6 0 12 5.4 12 12v24z"
        class=""
      ></path>
    </svg>
  ),
  calculator: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="calculator-alt"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="svg-inline--fa fa-calculator-alt fa-w-16"
    >
      <path
        fill="currentColor"
        d="M477.71 0H34.29C15.35 0 0 15.35 0 34.29v443.43C0 496.65 15.35 512 34.29 512h443.43c18.94 0 34.29-15.35 34.29-34.29V34.29C512 15.35 496.65 0 477.71 0zM232 464H48V280h184v184zm0-232H48V48h184v184zm232 232H280V280h184v184zm0-232H280V48h184v184zm-360-72h80c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-80c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm224 248h80c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-80c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm0-48h80c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-80c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm0-200h24v24c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-24h24c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-24v-24c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v24h-24c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zM104.4 396.28l11.31 11.31c3.12 3.12 8.19 3.12 11.31 0L144 390.63l16.97 16.97c3.12 3.12 8.19 3.12 11.31 0l11.31-11.31c3.12-3.12 3.12-8.19 0-11.31L166.63 368l16.97-16.97c3.12-3.12 3.12-8.19 0-11.31l-11.31-11.31c-3.12-3.12-8.19-3.12-11.31 0L144 345.37l-16.97-16.97c-3.12-3.12-8.19-3.12-11.31 0l-11.31 11.31c-3.12 3.12-3.12 8.19 0 11.31L121.37 368l-16.97 16.97c-3.12 3.12-3.12 8.19 0 11.31z"
        class=""
      ></path>
    </svg>
  ),
  rock: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="hand-rock"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="svg-inline--fa fa-hand-rock fa-w-16"
    >
      <path
        fill="currentColor"
        d="M408.864 79.052c-22.401-33.898-66.108-42.273-98.813-23.588-29.474-31.469-79.145-31.093-108.334-.022-47.16-27.02-108.71 5.055-110.671 60.806C44.846 105.407 0 140.001 0 187.429v56.953c0 32.741 14.28 63.954 39.18 85.634l97.71 85.081c4.252 3.702 3.11 5.573 3.11 32.903 0 17.673 14.327 32 32 32h252c17.673 0 32-14.327 32-32 0-23.513-1.015-30.745 3.982-42.37l42.835-99.656c6.094-14.177 9.183-29.172 9.183-44.568V146.963c0-52.839-54.314-88.662-103.136-67.911zM464 261.406a64.505 64.505 0 0 1-5.282 25.613l-42.835 99.655c-5.23 12.171-7.883 25.04-7.883 38.25V432H188v-10.286c0-16.37-7.14-31.977-19.59-42.817l-97.71-85.08C56.274 281.255 48 263.236 48 244.381v-56.953c0-33.208 52-33.537 52 .677v41.228a16 16 0 0 0 5.493 12.067l7 6.095A16 16 0 0 0 139 235.429V118.857c0-33.097 52-33.725 52 .677v26.751c0 8.836 7.164 16 16 16h7c8.836 0 16-7.164 16-16v-41.143c0-33.134 52-33.675 52 .677v40.466c0 8.836 7.163 16 16 16h7c8.837 0 16-7.164 16-16v-27.429c0-33.03 52-33.78 52 .677v26.751c0 8.836 7.163 16 16 16h7c8.837 0 16-7.164 16-16 0-33.146 52-33.613 52 .677v114.445z"
        class=""
      ></path>
    </svg>
  ),
  paper: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="hand-paper"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      class="svg-inline--fa fa-hand-paper fa-w-14"
    >
      <path
        fill="currentColor"
        d="M372.57 112.641v-10.825c0-43.612-40.52-76.691-83.039-65.546-25.629-49.5-94.09-47.45-117.982.747C130.269 26.456 89.144 57.945 89.144 102v126.13c-19.953-7.427-43.308-5.068-62.083 8.871-29.355 21.796-35.794 63.333-14.55 93.153L132.48 498.569a32 32 0 0 0 26.062 13.432h222.897c14.904 0 27.835-10.289 31.182-24.813l30.184-130.958A203.637 203.637 0 0 0 448 310.564V179c0-40.62-35.523-71.992-75.43-66.359zm27.427 197.922c0 11.731-1.334 23.469-3.965 34.886L368.707 464h-201.92L51.591 302.303c-14.439-20.27 15.023-42.776 29.394-22.605l27.128 38.079c8.995 12.626 29.031 6.287 29.031-9.283V102c0-25.645 36.571-24.81 36.571.691V256c0 8.837 7.163 16 16 16h6.856c8.837 0 16-7.163 16-16V67c0-25.663 36.571-24.81 36.571.691V256c0 8.837 7.163 16 16 16h6.856c8.837 0 16-7.163 16-16V101.125c0-25.672 36.57-24.81 36.57.691V256c0 8.837 7.163 16 16 16h6.857c8.837 0 16-7.163 16-16v-76.309c0-26.242 36.57-25.64 36.57-.691v131.563z"
        class=""
      ></path>
    </svg>
  ),
  scissors: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="hand-scissors"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="svg-inline--fa fa-hand-scissors fa-w-16"
    >
      <path
        fill="currentColor"
        d="M256 480l70-.013c5.114 0 10.231-.583 15.203-1.729l118.999-27.427C490.56 443.835 512 417.02 512 386.277V180.575c0-23.845-13.03-45.951-34.005-57.69l-97.999-54.853c-34.409-19.261-67.263-5.824-92.218 24.733L142.85 37.008c-37.887-14.579-80.612 3.727-95.642 41.201-15.098 37.642 3.635 80.37 41.942 95.112L168 192l-94-9.141c-40.804 0-74 32.811-74 73.14 0 40.33 33.196 73.141 74 73.141h87.635c-3.675 26.245 8.692 51.297 30.341 65.006C178.657 436.737 211.044 480 256 480zm0-48.013c-25.16 0-25.12-36.567 0-36.567 8.837 0 16-7.163 16-16v-6.856c0-8.837-7.163-16-16-16h-28c-25.159 0-25.122-36.567 0-36.567h28c8.837 0 16-7.163 16-16v-6.856c0-8.837-7.163-16-16-16H74c-34.43 0-34.375-50.281 0-50.281h182c8.837 0 16-7.163 16-16v-11.632a16 16 0 0 0-10.254-14.933L106.389 128.51c-31.552-12.14-13.432-59.283 19.222-46.717l166.549 64.091a16.001 16.001 0 0 0 18.139-4.812l21.764-26.647c5.82-7.127 16.348-9.064 24.488-4.508l98 54.854c5.828 3.263 9.449 9.318 9.449 15.805v205.701c0 8.491-5.994 15.804-14.576 17.782l-119.001 27.427a19.743 19.743 0 0 1-4.423.502h-70z"
        class=""
      ></path>
    </svg>
  ),
  shuttle: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="shuttlecock"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="svg-inline--fa fa-shuttlecock fa-w-16"
    >
      <path
        fill="currentColor"
        d="M484 192h-20c0 6 4.3 55.7-44.7 71.2L328.1 292l-26.4 45.4-78 36.3-25.7-25.8 70.8-70.8 140.8-44.5c13.3-4.2 22.4-16.6 22.4-30.5V108c0-15.5-12.5-28-28-28h-94.1c-14 0-26.3 9.1-30.5 22.4l-44.5 140.8-70.8 70.8-25.7-25.7 36.3-78 45.4-26.4 28.8-91.2C263.4 46.7 307.7 48 320 48V28c0-15.4-12.6-28-28-28h-54.6c-10.8 0-20.8 6.3-25.4 16.2L95.5 266.5l-39.2 39.2 150 150 39.2-39.2L495.8 300c9.8-4.6 16.2-14.5 16.2-25.4V220c0-15.4-12.6-28-28-28zM31.1 331c-41.4 41.4-41.4 108.6 0 150 41.4 41.4 108.6 41.4 150 0l2.6-2.6-150-150-2.6 2.6z"
        class=""
      ></path>
    </svg>
  ),
  cutlery: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="utensils"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 544 512"
      class="svg-inline--fa fa-utensils fa-w-17"
    >
      <path
        fill="currentColor"
        d="M288 157.5c0-30.5-12.9-97.8-15.6-111.7C267.5 20.1 244.1 0 210.6 0c-11.4 0-23.1 2.4-33.3 7.8C167.3 2.5 155.5 0 144 0c-11.5 0-23.3 2.5-33.3 7.8C100.6 2.4 88.8 0 77.4 0 44.1 0 20.5 19.9 15.6 45.8 12.9 59.6 0 126.9 0 157.5c0 52.7 28.2 94.8 69.8 116.7L59.6 454.9c-1.8 31 23.1 57.1 54.4 57.1h60c31.3 0 56.2-26.1 54.4-57.1l-10.2-180.8c41.4-21.7 69.8-63.8 69.8-116.6zm-119.7 83.6l12.2 216.5c.2 3.4-2.7 6.4-6.5 6.4h-60c-3.7 0-6.7-2.9-6.5-6.4l12.2-216.5C77.3 233 48 201.3 48 157.5c0-27.6 14.8-102.7 14.8-102.7 1.6-9.2 28.3-9 29.5.2v113.7c.9 10.6 28.2 10.8 29.5.2l7.4-114.1c1.6-9 27.9-9 29.5 0l7.4 114.1c1.3 10.6 28.6 10.4 29.5-.2V55c1.2-9.2 27.9-9.4 29.5-.2 0 0 14.8 75.1 14.8 102.7.1 43.6-29 75.4-71.6 83.6zm221.2 69.5l-13.3 142.5c-2.9 31.6 22.7 58.9 55.8 58.9h56c30.9 0 56-24.2 56-54V54c0-29.8-25.1-54-56-54-71.8 0-168 83-168 181.7 0 60.4 35 101.2 69.5 128.9zM368 181.7C368 109.1 443.4 48 488 48c4.3 0 8 2.8 8 6v404c0 3.3-3.7 6-8 6h-56c-4.6 0-8.3-3-8-6.4l15.8-169.5c-39.6-27-71.8-59-71.8-106.4z"
        class=""
      ></path>
    </svg>
  ),
};

export const projectsArray = [
  {
    name: "DJ Tomiwa",
    icon: <img src={djtomiwa} alt="DJ Tomiwa logo" />,
    skills: "React, Styled components, AWS, CircleCI",
    description: (
      <p>
        I built a website for a client that wanted to update the look of their
        site.
      </p>
    ),
    githubUrl: "https://github.com/Wale-K/dj-tomiwa",
    browserUrl: "https://djtomiwa.olawalekoiki.co.uk/",
  },
  {
    name: "Recipe Finder",
    icon: icons.cutlery,
    skills: "React, Axios, AWS, CircleCI",
    description: (
      <p>
        An app that allows a user to enter one or more ingredients and provides
        them with recipes that use these ingredients.
      </p>
    ),
    githubUrl: "https://github.com/Wale-K/recipe-finder",
    browserUrl: "https://recipe.olawalekoiki.co.uk/",
  },
  {
    name: "Badminton Scoresheet",
    icon: icons.shuttle,
    skills: "React, Redux, AWS, CircleCI",
    description: (
      <p>
        An app that allows you to keep the score of a badminton game of singles
        or doubles.
      </p>
    ),
    githubUrl: "https://github.com/Wale-K/badminton-scoresheet",
    browserUrl: "https://badminton.olawalekoiki.co.uk/",
  },
  {
    name: "Everyday Kanji",
    icon: <p>漢字</p>,
    // skills: "React, Axios, Redux, AWS, CircleCI",
    skills: "React, Axios, AWS, CircleCI",
    description: (
      <p>
        A Japanese language study app made using the Kanji API, made for my
        personal use on a laptop or a wide screen.
      </p>
    ),
    githubUrl: "https://github.com/Wale-K/kanji",
    browserUrl: "http://kanji.olawalekoiki.co.uk/",
  },
  //   {
  //     name: "Pokédex",
  //     icon: "P",

  //     skills: "React, Axios, AWS, CircleCI",
  //     description: (
  //       <p>A work in progress of a pokédex made using the Poke API.</p>
  //     ),
  //     githubUrl: "https://github.com/Wale-K/pokedex",
  //     browserUrl: "http://pokedex.olawalekoiki.co.uk/",
  //   },
  {
    name: "Rock Paper Scissors",

    icon: (
      <JankenDiv>
        <StyledRock>{icons.rock}</StyledRock>
        <div>
          <StyledPaper>{icons.paper}</StyledPaper>
          <StyledScisscors>{icons.scissors}</StyledScisscors>
        </div>
      </JankenDiv>
    ),
    skills: "React, Styled components, AWS, CircleCI",
    description: <p>A game based off the classic "Rock Paper Scissors".</p>,
    githubUrl: "https://github.com/Wale-K/janken-react",
    browserUrl: "http://janken.olawalekoiki.co.uk/",
  },
  {
    name: "Calculator",
    icon: icons.calculator,
    skills: "HTML, CSS, Javascript, AWS, CircleCI",
    description: (
      <p>
        A calculator with "plus", "minus", "multiply" and "divide"
        functionality.
      </p>
    ),
    githubUrl: "https://github.com/Wale-K/calculator-vanilla",
    browserUrl: "http://calculator.olawalekoiki.co.uk/",
  },
];
